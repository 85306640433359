import moment from 'moment';
import { giveIPAFieldList, disburseLoanFieldList, ipaFormFields } from '../mixins/localeConfig';

import { useDomainStore } from '../store/domain';
import { useNuxtStore } from '../store/nuxt';

export default {
  computed: {
    offerFieldsText () {
      return this.domainStore.offerFieldsText();
    },
    prefixes () {
      return this.domainStore.prefixes();
    },
    portalUsers () {
      return this.domainStore.portalUsers();
    },
    // get field list
    list () {
      const listing = (this.offerFunnel === 'post-disburse') ? disburseLoanFieldList : giveIPAFieldList;
      return listing[this.locale];
    },
    // get and construct field properties
    fieldProps () {
      const fields = {};
      this.list.forEach((item) => { fields[item] = ipaFormFields[this.locale][item] });

      return fields;
    },
    locale () { return this.nuxtStore.locale },
    language () { return this.nuxtStore.language },
  },

  data () {
    return {
      buttonClicked: false,
      domainStore: useDomainStore(),
      nuxtStore: useNuxtStore(),
    }
  },

  methods: {
    fieldLabel (field) {
      if (this.offerFieldsText[field]) return this.offerFieldsText[field].label;
      return field;
    },
    getPrefix (prefix) {
      if (this.prefixes[prefix]) return this.prefixes[prefix].label;
      return prefix;
    },
    // string together field data display
    fieldData (field, val) {
      if (field === 'bankApprovedBy') {
        const approvedBy = this.portalUsers.find(u => u._id === val);
        if (!approvedBy) return '-';
        return approvedBy.lastName ? `${approvedBy.lastName} ${approvedBy.firstName}` : approvedBy.firstName;
      }

      if (field === 'bankApprovedDate') {
        return (this.language === 'EN') ? moment(val).format('MMM D, YYYY h:mmA') : moment(val).format('YYYY年M月D日 h:mmA');
      }

      // handle date field
      if (this.fieldProps[field].type === 'date') {
        const date = new Date(val);
        return moment(val).format('DD/MM/YYYY');
        // return `${('0' + date.getDay()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
      }

      // handle checkbox field
      if (this.fieldProps[field].type === 'checkbox') {
        return (val) ? 'Yes' : 'No';
      }

      if (!this.fieldProps[field].prefix) return val;

      // handle fields with prefixes
      const { prefix: { prefixFront, prefixBack } } = this.fieldProps[field];
      let dataStr = '';

      if (field !== 'loanPeriod') val = Number(val).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (prefixFront) dataStr = this.getPrefix(prefixFront);
      dataStr = `${dataStr}${val}`;
      if (prefixBack) dataStr = `${dataStr} ${this.getPrefix(prefixBack)}`;

      return dataStr;
    }
  }
}
